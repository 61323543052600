.footer {
  min-height: $footer-height;
  background: $linear-background;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $md-breakpoint) {
    padding: 1rem 0;
  }

  .footer-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $md-breakpoint) {
      padding: 1rem 0;
    }
    .footer-logo-container {
      max-width: 10.625rem;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 8rem;
      }
      .footer-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .footer-socialmedia-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .footer-socialmedia {
        width: 9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .call {
    
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          color: $white;
          text-decoration: none;
          
          @media screen and (max-width: $sm-breakpoint) {
            font-size: 0.875rem;
          }

          img {
            width: 1.1625rem;
            height: 1.25rem;

            @media screen and (max-width: $sm-breakpoint) {
              width: 1rem;
              height: 1.1rem;
            }
          }
        }

        .socialmedia {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: $sm-breakpoint) {
            justify-content: space-around;
          }

          .socialmedia-icons {
            width: 2.1875rem;
            height: 2.1875rem;
            transition: 0.2s;

            @media screen and (max-width: $sm-breakpoint) {
              width: 1.75rem;
              height: 1.75rem;
            }

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .footer-links {
      color: $white !important;
      text-transform: none !important;
      font-size: 1rem !important;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: 0.875rem !important;
      }

      .links {
        display: flex;
        justify-content: space-between;
        align-items: center;


        @media screen and (max-width: $sm-breakpoint) {
          justify-content: center;
        }

        @media screen and (max-width: $md-breakpoint) {
          margin-top: 1.5rem;
        }

        .footerlinks {
          text-decoration: none;
          transition: 0.2s;
          transform-origin: center;
          text-transform: none !important;
          color: $white;
          font-size: $font-size-sm;

          transition: cubic-bezier(0.19, 1, 0.22, 1);
          &:hover {
            transform: scale(1.05);
            
          }
        }
        .divider{
          color: $white;
          font-weight: $font-weight-bold;
          &:last-child{
            display: none;
          }
        }
      }


      .lower-content {
        text-align: center;
        .content {
          font-size: $font-size-sm;
          color: $white;
          @media screen and (max-width: $sm-breakpoint) {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}
