.loader-container {
  display: flex;
  justify-content: center;
  .loader {
    color: $primary;
  }
  .loader-white {
    color: white;
    margin-top: 10px;
  }
}
.loader-start {
  justify-content: start;
  width: 10%;
}

.loader-text {
  display: inline-block;
}

.back-drop {
  color: $white;
  background: $primary !important;
  span {
    height: 100px !important;
    width: 100px !important;
  }
}