.shared-cost-application-header {
  height: 3.125rem;
  background-color: $field-background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.75rem;
  font-size: $font-size-base !important;
  font-weight: $font-weight-bold;
  color: $black;
  margin-bottom: 1.4375rem;
  @media screen and (max-width: $sm-breakpoint) {
    flex-direction: column;
    min-height: 3.125rem;
    max-height: 3.5rem;
    height: 100%;
    font-size: $font-size-sm;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .choosenAmount {
    padding-right: 0.75rem;
    span {
      color: $success;
    }

    .modified-span {
      color: $light-primary !important;
    }
  }
}

.long-header {
  height: 3.75rem !important;

  @media screen and (max-width: $sm-breakpoint) {
    min-height: 6rem !important;
  }

  @media screen and (max-width: $xs-breakpoint) {
    min-height: 8rem !important;
  }
}
