.header {
  width: 100%;
  height: $header-height;
  background: $linear-background;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 999;
  box-shadow: 0 10px 16px rgb(0, 0, 0, 10%);

  @media screen and (max-width: $sm-breakpoint) {
    height: $header-height-xs;
  }
  .header-logo-container {
    max-width: 10.625rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $sm-breakpoint) {
      max-width: 8rem;
    }
    .header-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .mobile-header-logo-container {
    margin-left: 1rem;
    max-width: 8rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .header-links-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $sm-breakpoint) {
      display: block;
    }
    .header-links-container {
      display: flex;
      align-items: flex-start;
      width: 100%;

      .header-links {
        color: $white;
        max-width: 8.9375rem !important;
        width: 100%;
        text-decoration: none;
        text-transform: capitalize !important;
        text-align: left;
        font-size: 1rem;
        line-height: 1.3rem;
      }
    }
  }
  .header-Dropdown-container {
    display: flex;
    justify-content: flex-end;
  }
  .menu-container {
    border-radius: 0.375rem;
    max-width: 9.625rem;
    width: 100% !important;
    box-shadow: 0px 3px 6px #00000054;

    .menu-title {
      font-weight: bold;
      margin-left: 4px;
    }
    .menu-list {
      font-size: 0.875rem !important;
      width: 100% !important;
      padding: 2px 15px;
    }
  }
}

// Header Mobile Links
.headerMobile {
  .menuIcon {
    color: white;
    font-size: 2rem;
  }
}
.headerMobile-drower {
  .header-links-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;

    .header-links-container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      @media screen and (max-width: $md-breakpoint) {
        flex-direction: column;
        justify-content: flex-start;
      }

      .header-links {
        color: $white;
        max-width: 8.9375rem;
        text-decoration: none;
        display: flex;
        justify-content: center;
        text-transform: capitalize !important;
        align-items: center;
        padding: 1rem;
        width: 100%;

        @media screen and (max-width: $md-breakpoint) {
          color: black;
          align-items: flex-start;
          justify-content: flex-start;
          max-width: 100%;
        }
      }
      .header-links:hover {
        background-color: #f2f2f2 !important;
      }
      .header-links:active {
        background-color: #f2f2f2 !important;
      }
    }
  }
  .logout-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 2rem;
    margin-left: 1rem;
    
  }
}


.nav-links {
  color: inherit;
  text-decoration: none;
  text-transform: capitalize;
}