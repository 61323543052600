.organisation-large-logo {
  height: 10.5rem;
  width: 100%;
  margin-top: 12rem;

  .organisation-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.no-plan-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .strong-heading {
    color: $secondary;
  }

  .bottom-header {
    background-color: $lighter-secondary;
    width: 38rem;
    padding: 0rem 3.5rem 1.5rem 3.5rem;
    border-radius: 0.5rem;

    @media screen and (max-width: $sm-breakpoint) {
      width: 17rem;
      padding: 0rem 1rem 1.75rem 1rem;
    }

    @media screen and (max-width: $xs-breakpoint) {
      width: 14rem;
      padding: 0rem 0.5rem 1.5rem 0.5rem;
    }
  }
}


.back-img {
  height: 80vh;
  z-index: -9;
  margin-top: -35%;

  @media screen and (max-width: $sm-breakpoint) {
    height: 55vh;
    margin-top: -100%;

  }

  @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
    height: 60vh;
    margin-top: -80%;
  }

}

.back-img-sm {
  height: 31vh;
  z-index: -9 !important;
  margin-top: -15%;

  @media screen and (max-width: $lg-breakpoint) {
    height: 34vh;
    margin-top: -25%;
  }

  @media screen and (max-width: $md-breakpoint) {
    height: 33vh;
    margin-top: -31%;
  }

  @media screen and (max-width: $sm-breakpoint) {
    height: 40vh;
    margin-top: -61%;
  }

  @media screen and (max-width: $xs-breakpoint) {
    height: 35vh;
    margin-top: -100%;
  }
}

.plan-detail-container {
  width: 100%;
  border: 1px solid #D8D4D3;
  background-color: $white;

  .banner-container {
    width: 100%;
    height: 11.25rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1.875rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0.875rem;
    }

    .heading {
      color: $white;
      font-size: 1.5rem;
      font-weight: $font-weight-bold;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-lg;
      }
    }

    .icon-container {
      width: 4.25rem;
      height: 4.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .status-heading {
      color: $white;
      font-weight: bold;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
      }
    }

    .status-container {
      height: 2.25rem;
      min-width: 7.5rem;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.5rem;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
      }
    }

    .cancel {
      background-color: #D14545;
    }

    .awaiting {
      background-color: #FFBF00;
    }

    .accept {
      background-color: #27A737;
    }
  }

  .info-container {
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.875rem;
    background-color: $white;

    @media screen and (max-width: $sm-breakpoint) {
      flex-direction: column;
      padding: 0.875rem;
    }

    .info {
      .plan {
        color: $secondary;
        font-size: 1.375rem;
        font-weight: $font-weight-bold;

        @media screen and (max-width: $sm-breakpoint) {
          font-size: $font-size-lg;
        }
      }

      .content {
        color: $black;
        font-size: $font-size-base;

        @media screen and (max-width: $sm-breakpoint) {
          font-size: $font-size-sm;
        }
      }
    }

    .button-container {
      @media screen and (max-width: $sm-breakpoint) {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
        width: 65%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
      }

      .filled-button {
        width: 17rem;
        height: 3.125rem;
        border-radius: 0.5rem;
        text-transform: capitalize !important;

        @media screen and (max-width: $sm-breakpoint) {
          width: 15rem;
          font-size: $font-size-sm
        }

        @media screen and (max-width: $xs-breakpoint) {
          width: 13rem;
          font-size: 0.850rem !important;
        }
      }

      .download {
        color: #171C48;
        border: 1px solid #171C48;
        background-color: $white;
      }

      .cancel {
        color: #BE1212;
        border: 1px solid #BE1212;
        background-color: $white;
      }

      .amend {
        color: $white;
        border: 1px solid #171C48;
        background-color: #171C48;
      }
    }
  }
}

.plan-guidance-container {
  background-color: $white;
  border: 1px solid #D8D4D3;
  padding: 1.875rem;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.875rem;
  }

  .plan-guidance-heading {
    font-size: 1.375rem;
    color: $secondary;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-base;
    }
  }

  .plan-guidance-content {
    color: $black;
    font-size: $font-size-base;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}

.video-container {
  height: 29.5625rem;
  width: 100%;

  @media screen and (max-width: $sm-breakpoint) {
    height: 11.5625rem;
  }
}

.service-container {
  padding: 0 1rem;

  @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
    display: flex;
    justify-content: center;
  }

  .service-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .service-icon {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .service-content {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      color: $secondary;
      text-align: center;


      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
      }
    }
  }

}

.customer-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .customer-service-content {
    font-size: $font-size-base;
    color: $light-primary;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }

  .customer-service-button {
    width: 10rem;
    height: 3.125rem;
    background-color: #171C48;
    color: $white;
    border-radius: 0.5rem;
    text-transform: capitalize !important;

    &:hover {
      background-color: #171C48;
    }
  }
}

.my-plan-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.125rem;
  width: 85.875;
  background: $linear-background;
  padding: 1rem;

  .my-plan-heading {
    color: $white;
    font-weight: bold;
    font-size: 1.250rem;
    margin-bottom: 3rem !important;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1rem !important;
    }
  }

  @media screen and (max-width: $sm-breakpoint) {
    height: 9.125rem;
  }
}

;

.cancel-plan-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  height: auto;
  min-height: 20rem;
  margin: 0 2rem;
  padding: 1rem;
  margin-top: -4rem !important;
  border: 1px solid $border-gray;
  text-align: center;
}

.cancel-plan-content-flex-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $white;
  height: auto;
  min-height: 20rem;
  margin: 0 2rem;
  padding: 1rem;
  margin-top: -4rem !important;
  border: 1px solid $border-gray;

  .padding-content-heading {
    font-size: $font-size-lg;
    color: $light-primary;
    padding: 0 1.875rem !important;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
      font-size: $font-size-base;
    }
  }

  .padding-content {
    padding: 0 1.875rem !important;
    color: $black;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
    }
  }
}

.width-restriction {
  min-width: fit-content !important;

  @media screen and (max-width: $sm-breakpoint) {
    margin: 0 1rem !important;
    margin-top: -4rem !important;
  }
}

.avc-amount-box {
  width: 100%;
  border: 1px solid #D8D4D3;
  min-height: 10rem;
  padding: 1rem;
}

.generic-heading-cotainer {
  width: 100%;
  height: 3.75rem;
  background: $linear-background;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem 2rem;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0 0.2rem;
  }

  .generic-heading {
    color: $white;
    font-size: 1rem;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}

.flex-start {
  justify-content: flex-start !important;
  align-items: center !important;
}

.amend-form-tab {
  background-color: $lightest-primary;
  padding: 0.3rem 1rem;
  border-radius: 0.25rem;
  height: 3.650rem;

  @media screen and (max-width: $md-breakpoint) {
    height: auto;
  }

  .form-text {
    font-size: 14px;
  }

  .underline-text {
    text-decoration: underline;
  }
}

.amend-page-accordian {
  .summary {
    border: none !important;
    background-color: $light-secondary !important;

    .MuiAccordionSummary-expandIconWrapper {
      color: $moderate-primary !important;
    }
  }

  .summary-text {
    font-weight: bold !important;
    color: $moderate-primary !important;
  }
}

.amendment-reason-box {
  padding: 1.3rem;
}

.amend-descripton-box {
  background-color: $moderate-gray;
  padding: 1rem;
}