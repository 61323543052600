// Text Classes
.primary-text {
  color: $primary !important;
}

.moderate-primary-text {
  color: $moderate-primary !important;
}

.success-text {
  color: $success !important;
}

.warning-text {
  color: $yellow !important;
}

.imp-text {
  color: $warning !important;
}

.grey-text {
  color: $light-gray;
}

.white-text {
  color: $white !important;
}

.bg-moderate-red {
  background-color: $moderate-red !important;
}

.bg-primary {
  background-color: $primary !important;
}

.web-link {
  color: $primary;
  text-decoration: none;
  word-break: break-all;
}

.none-text-transform {
  text-transform: none !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.large-heading {
  font-size: 1.5rem !important;
}

.stepper-content {
  font-size: $font-size-base !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm !important;
  }
}

//font-size
.fs-28 {
  font-size: 28px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.p-lr-30 {
  padding: 0 1.875rem !important;
}

.p-lr-50 {
  padding: 0 4.50rem !important;
}

// Margin Classes
.mt-5 {
  margin-top: 0.3125rem !important;
}

.mr-5 {
  margin-right: 0.3125rem !important;
}

.mr-10 {
  margin-right: 0.625rem !important;
}

.mr-30 {
  margin-right: 1.875rem !important;
}

.ml-10 {
  margin-left: 0.625rem !important;
}

.mt-10 {
  margin-top: 0.60rem !important;
}

.mt-12 {
  margin-top: 0.75rem !important;
}

.mt-14 {
  margin-bottom: 0.875rem !important;
}

.ml-18 {
  margin-left: 1.125rem !important;
}

.ml-30 {
  margin-left: 1.875rem !important;
}

.mt-18 {
  margin-top: 1.125rem !important;
}

.mr-18 {
  margin-right: 1.125rem !important;
}

.mb-18 {
  margin-bottom: 1.125rem !important;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.ml-36 {
  margin-left: 2.250rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.mb-85 {
  margin-bottom: 5.70rem !important;
}

.mt-100 {
  margin-top: 6.850rem !important;
}

.mb-100 {
  margin-bottom: 6.850rem !important;
}

.mt-100 {
  margin-top: 6.850rem !important;
}

.mt-150 {
  margin-top: 16rem !important;
}

.mt-200 {
  margin-top: 21rem !important;
}

.mb-200 {
  margin-bottom: 21rem !important;
}

.mt-250 {
  margin-top: 25rem !important;
}

.mb-250 {
  margin-bottom: 25rem !important;
}

.application-page-container {
  min-height: 31.5rem;
  height: 100%;

  @media screen and (max-width: $sm-breakpoint) {
    min-height: 25.5rem;
  }
}

.modal-background {
  background-color: rgba($color: #000000, $alpha: 0.57);
}

.cursor-pointer {
  cursor: pointer;
}

// Icons
.delete-icon {
  color: $red-icon;
  cursor: pointer;
}

.arrow-right {
  color: $primary;
}

.input-field-icon {
  color: $field-icon-black;
  font-size: $font-size-lg !important;
}

.error-outline {
  color: $red-icon;
  font-size: $font-size-lg !important;
}

//stepper-field

.stepper-field {
  max-width: 31.25rem !important;
  width: 100% !important;
}

.stepper-content {
  font-size: $font-size-base !important;
  color: $black;
  line-height: $headings-line-height !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-sm !important;
  }
}

//sub heading
.sub-heading {
  color: $primary !important;
  font-size: $font-size-lg !important;
  font-weight: $font-weight-bold !important;
  line-height: $headings-line-height !important;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: $font-size-base !important;
  }
}

.strongContent {
  color: $black;
  font-weight: $font-weight-bold;
}

.investedFund-profit {
  color: $success;
  font-weight: $font-weight-bold;
}

.investedFund {
  color: $primary;
  font-weight: $font-weight-bold;
}

.lnh-2 {
  line-height: 2rem !important;
}

.grey-color {
  background-color: $light-gray !important;
}

.flex-box-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100% !important;
}

.half-width {
  width: 50% !important;
}