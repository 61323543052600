.shared-cost-avc-amount-step-3 {
  .planFrequency {
    width: 7.25rem !important;
    height: 4.0625rem !important;
  }
  .potworth-amount-container {
    background: $linear-background;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    .content,
    .amount {
      color: $white;
    }
    .content {
      font-size: $font-size-lg;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-base;
      }
    }
  }
  .potworth-green-container {
    background: $linear-green-background;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    .content,
    .amount {
      color: $white;
    }
    .content {
      font-size: $font-size-lg;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-base;
      }
    }
    .loader-start {
      color: $white;
      width: 50px;
      height: 50px;
      .loader-container .loader {
        color: white;
      }
    }
    .pot-worth-amount {
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }
  .slider {
    max-width: 20.5625rem;
    width: 100%;
    color: $primary;
  }
  .amount {
    font-size: 1.375rem;
    font-weight: $font-weight-bold;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-lg;
    }
  }
}
.potworth-container {
  justify-content: start;
  .pot-worth-text {
    font-size: 0.75rem;
    color: $primary;
  }
  .pot-worth-amount {
    font-size: $font-size-lg;
    color: $success;
    font-weight: $font-weight-bold;
  }
}
.btn-list {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  width: -moz-available;
  .list-item {
    width: 100%;
    color: $light-gray;
    max-width: 6.4375rem;
    height: 2.4375rem;
    padding: 0.3rem 1rem;
    border: 1px solid $primary;
    text-align: center;
    &:hover,
    &.Mui-selected {
      background: $primary;
      color: $white;
    }

    @media (hover: none) {
      &.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected:hover {
        background-color: $primary;
      }
    }
    span {
      font-size: 0.75rem;
      font-weight: $font-weight-bold;
    }
  }
  .item-1 {
    border-radius: 0.3rem 0 0 0.3rem;
  }
  .item-3 {
    border-left: none;
    border-radius: 0 0.3rem 0.3rem 0;
  }
}
