.shared-cost-avc-amount-step-2 {
  
  .info-container {
    background-color: #236aa31a;
    width: 100%;
    height: 6.375rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: .25rem;
    padding: 1rem;
    @media screen and (max-width: $sm-breakpoint) {
      height: 8.375rem;
    }
    .info-text {
      font-size: $font-size-sm;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: 0.75rem;
      }
    }
  }
  .update-btn {
    background: $success;
    &:hover {
      background: $success;
    }
  }
}
