.SharedHeader {
  position: fixed;
  z-index: 99;
  height: $shared-header-height;
  width: 100%;
  top: $header-height-logo;
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @media screen and (max-width: $sm-breakpoint) {
    top: $header-height-xs;
    height: $shared-header-height-xs;
  }

  .SharedHeader-sub-heading {
    font-size: $font-size-base;
    color: $text-dark-black;
    font-weight: $font-weight-medium;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }

  .SharedHeader-sideline {
    width: 5px;
    height: 2.5rem;
    background-color: $primary;
    border-radius: 1.25rem;

    @media screen and (max-width: $sm-breakpoint) {
      height: 2rem;
    }
  }

  .SharedHeader-heading {
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
    color: $primary;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-lg;
    }
  }
  .organisation-logo-container{
    max-width: 10rem;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $sm-breakpoint) {
      max-width: 6rem;
    }
    .organisation-logo{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .bottomspace-sharedheader {
    height: 30px;
    width: 100%;
    background-color: $white;
    @media screen and (max-width: $sm-breakpoint) {
      height: 18px;
    }
  }

}

 