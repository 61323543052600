.event-page-header {
  width: 100%;
  height: 11.25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: 100% 20%;
  background-size: cover;

  .event-page-heading {
    font-size: 0.9rem;
    color: $white;
  }

  .bold-heading {
    font-size: 1.40rem;
    font-weight: $font-weight-bold;
  }

}

.filter-button {
  border: 1px solid #025496;
  border-radius: 0.5rem;
  color: #025496;
  padding: 0.70rem 1.40rem;
  width: 9rem;
  height: 3rem;
  cursor: pointer;

  .filter-text {
    font-weight: bold;
  }
}

.filter-button-disabled {
  border: 0px !important;
}

.event-box {
  width: 100%;
  border: 1px solid #E3E3E3;
  min-height: 14.350rem;
  height: auto;
  padding: 1rem 3rem;

  @media screen and (max-width: $sm-breakpoint) {
    min-width: 20rem;
  }

}

.stamp-box {
  border: 2px solid #025496;
  border-radius: 5px;
  width: 15.250rem;
  height: 10rem;

  .stamp-header {
    text-align: center;
    color: #FFFFFF;
    padding: 0.75rem;
    width: 100%;
    height: 55%;
    background: #025496 0% 0% no-repeat padding-box;
  }

  .standard-heading {
    font-size: 0.80rem;
  }

  .bold-heading {
    font-size: 0.85rem;
    font-weight: bold;
  }

  .stamp-button-box {
    padding: 0.9rem 1.7rem;
  }
}

.im-header {
  background: $light-greenish;
  width: 100%;
  height: 4.5rem;
  padding: 1rem 1.5rem;

  .im-heading {
    color: $black;
    font-weight: bold;
    font-size: 16px !important;

    @media screen and (max-width: $md-breakpoint) {
      font-size: 14px !important;
    }

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 12px;
    }
  }

  @media screen and (max-width: $sm-breakpoint) {
    height: auto;
  }
}

.stamp-page {
  width: 100%;
  border: 1px solid #E3E3E3;
  height: auto;
  padding: 1rem 1rem;

  .event-heading-container {
    width: 100%;
    height: 3.75rem;
    background: $linear-background;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;

    .event-heading {
      color: $white;
      font-size: 1rem;
      font-weight: $font-weight-bold
    }


  }

  .event-details-container {
    padding: 1rem 3rem;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 1rem 0rem;
    }

    .booking_btn {
      width: 150px;
      height: 40px;
      text-transform: none !important;
      color: $white;
      border-radius: 0.5rem;
      font-weight: bold;
    }

    .not-booked {
      background-color: $primary;
    }

    .booked {
      background-color: $moderate-red;
    }

    .join-now {
      background-color: $greenish;
    }

    .book-fast {
      background-color: $amber;
      color: $black;
      border: 2px solid $amber;
      border-radius: 1.25rem;
    }
  }

}

.event-filter-dialog {
  .MuiDialog-paper {
    width: 37.5rem;
    border-radius: 1rem;
    padding: 0.25rem;
  }

  .dialog-header {
    padding: 0 !important;
    margin: 1rem;
    border-bottom: 1px solid $light-gray;
  }
}

.cancel-confirmation-dialog {
  .MuiDialog-paper {
    width: 32.5rem !important;
  }

  .detail-heading-box {
    padding: 1rem 2.5rem !important;
  }
}

.event-confirmation-dialog {
  .MuiDialog-paper {
    width: 37.5rem;
    border-radius: 1rem;
  }

  .dialog-header {
    padding: 0 !important;
    background-color: $dark-primary;
    text-align: center;
    height: 4.250rem;

    .dialog-heading {
      font-weight: bold;
      color: $white;
      font-size: 1.0625rem !important;
      padding: 1.5rem 2.5rem;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 0.925rem !important;
      }
    }
  }

  .detail-heading-box {
    text-align: center;
    padding: 1rem 4.5rem;
  }

  .text-left {
    text-align: left;
  }

  .detail-box {
    padding: 0rem 2.50rem;

    .time-detail-box {
      border: 1px solid $medium-gray;
      background-color: $moderate-gray;
      height: 7rem;
      padding: 0.5rem;

      .time-header {
        width: 100%;
        height: 3.25rem;
        background: $linear-background;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 1rem;
    
        .time-heading {
          color: $white;
          font-size: 1rem;
          font-weight: $font-weight-bold
        }
      }

      .disabled-text {
        color: $shed-grey;
      }

      @media screen and (max-width: $sm-breakpoint) {
        height: auto;
      }
    }

    .small-text {
      font-size: 14px !important;
    }
  }
}

.portal-banner {
  background-color: $white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $portal-banner-height;

  @media screen and (max-width: $sm-breakpoint) {
    height: $portal-banner-height-xs;
  }

  .banner {
    height: 11.750rem;
    width: 44.25rem;
    cursor: pointer;

    @media screen and (max-width: $md-breakpoint) {
      width: 37rem;
    }

    @media screen and (max-width: $sm-breakpoint) {
      height: 9.750rem;
      width: 23rem;
    }
  }
}