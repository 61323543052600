.organisation-selector-box {
  background-color: $white;
  height: auto;
  min-height: 20rem;
  margin: 0 2rem;
  padding: 1rem;
  margin-top: -4rem !important;
  border: 1px solid $border-gray;
  
  .organisation-box {
    width: 80%;

    .organisation-drop-down {
      width: 50%;
      
      @media screen and (max-width: $md-breakpoint) {
        width: 100%;
      }
    }
  }
}

.organisation-page-heading {
  @media screen and (max-width: $sm-breakpoint) {
    font-size: 0.9rem !important;
  }
}