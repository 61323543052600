.final-summary {
  .amount-container {
    background-color: $field-background;
    height: 8.125rem;
    padding: 1rem 0;
    @media screen and (max-width: $sm-breakpoint) {
      height: 10rem;
    }
    
    .amount-text {
      padding-left: 1rem;
      color: $black;
      font-size: $font-size-lg;
    }
    .thanks-text {
      padding-left: 1rem;
      font-size: $font-size-sm;
      .success-text{
        font-size: $font-size-lg;
      }
    }
  }
  .note-container {
    padding: 1rem;
    border-radius: 4px;
    background: $light-pink;
    .note-heading-container {
      margin-bottom: 0.5rem;
      .warning-icon {
        color: $warning;
      }
      .note-heading {
        font-size: $font-size-sm;
        color: $warning;
        font-weight: $font-weight-bold;
        @media screen and (max-width: $sm-breakpoint) {
          font-size: .75rem;
        }
      }
    }
    .note-detail {
      font-size: $font-size-sm;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: .75rem;
      }
    }
  }
  .footer-text {
    font-size: $font-size-sm;
    color: $light-gray;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: .75rem;
    }
  }
}
