.checkbox-container {
  .form-control-label {
    align-items: start;
  }
  .checkbox {
    padding: 0;
    &.Mui-checked {
      color: $primary;
    }
  }
}
