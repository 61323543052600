.home-content {
  margin-top: 15rem;
  background-color: $white;
}

.shrink-box {
  width: 80%;

  @media screen and (max-width: $lg-breakpoint) {
    width: 100%;
  }
}

.finger-pointer {
  width: 75px;
  height: 75px;
}

.large-pointer-icon {
  width: 100px;
  height: 100px;
}

.saving-jars-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .saving-jars{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: $md-breakpoint) {
    height: auto;
    width: auto;
  }
}

.button-padding-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 6.875rem !important;

  @media screen and (max-width: $lg-breakpoint) {
    padding: 0 3.875rem !important;
  }

  @media screen and (max-width: $md-breakpoint) {
    flex-direction: column;
    gap: 2rem;
    justify-content: center !important;
    padding: 0rem !important;
  }
}

.button-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .dual-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.9rem;

    @media screen and (max-width: $xs-breakpoint) {
      flex-direction: column;
    }
  }

  .button-typography {
    color: $light-primary;
    text-align: center;
    font-weight: bold;
  }
}

.button-box-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: $md-breakpoint) {
    flex-direction: column;
  }

  .button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 13rem;
    padding: 1rem;
    width: 30%;

    @media screen and (max-width: $md-breakpoint) {
      min-height: 10rem;
      width: 80%;
    }

    @media screen and (max-width: $sm-breakpoint) {
      width: 100%;
    }
  }
}

.bg-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem 5rem !important;
  border-radius: 1rem !important;
  background-color: $light-secondary !important;

  @media screen and (max-width: $lg-breakpoint) {
    padding: 1rem 1.70rem !important;
    min-width: 20rem !important;
  }

  @media screen and (max-width: $xs-breakpoint) {
    min-width: 18rem !important;
    padding: 1rem 0rem !important;
  }
}

.sm-bg-footer {
  width: 70% !important;
}

.discover-box {
  text-align: center;

  .discover-typography {
    color: $light-primary;
    font-size: 1.125rem !important;
    text-align: center;
    font-weight: bold;
  }
}

.no-plan-dialog {
  .MuiDialog-paper {
    width: 34.5rem;
    border-radius: 1rem;
  }

  .dialog-header {
    padding: 0 !important;
    background-color: $dark-primary;
    text-align: center;
    height: 4.250rem;

    .dialog-heading {
      font-weight: bold;
      color: $white;
      font-size: 17px !important;
      padding: 1.5rem;
    }
  }

  .dialog-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 1rem;

    .organisation-logo {
      height: 8.875rem;
      width: auto;
    }
  }
}

.home-detail-section {
  background-color: $lightest-secondary;
  padding: 2.50rem 0;
  display: block;
  position: relative;
}

.home-detail-section:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 61%;
  height: 128px;
  background-color: $lightest-secondary;
  right: 0;
  top: -64px;
  z-index: 10;
  clip-path: ellipse(60% 95% at 63% 100%);
}

.home-detail-section:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 65%;
  height: 220px;
  background-color: $white;
  left: -10%;
  top: -185px;
  z-index: 5;
  clip-path: ellipse(50% 65% at 47% 100%);
}

.standard-avc-box {
  text-align: center;
  cursor: pointer;

  .avc-heading {
    color: $black;
    font-size: 0.9rem !important; 
    font-weight: bold;
  }
}